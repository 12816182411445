import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'

const NotFoundPage = () => (
  <Layout>
    <section id="banner" className="hero--internal">
      <div className="content">
        <header className="major special">
          <h1>NOT FOUND</h1>
          <p>You just hit a page that doesn&#39;t exist...</p>
        </header>
      </div>
    </section>
    <section className="wrapper style1 page">
      <div className="inner">
        <header className="major special">
          <h2>
            We couldn't find what you are looking for. <br />
            Please go back to the <Link to="/">Home Page</Link>
          </h2>
        </header>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
